<template>
  <div>
    <el-autocomplete
        v-model="leaderName"
        :fetch-suggestions="searchUser"
        placeholder="输入昵称或手机号检索用户"
        @select="handleUserSelect"
        @clear="initVerify"
        clearable>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "LeaderSearch",
  props: {
    keyword: {
      type: String,
      default() {
        return ""
      }
    }
  },
  computed: {
    leaderName: {
      get() {
        return this.keyword
      },
      set(val) {
        this.$emit("update:keyword", val)
      }
    }
  },
  methods: {
    // 初始化验证
    initVerify() {
      this.$emit("selected", {leader_id: null, nickname: null})
    },
    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      let list = await this.$api.searchUser(qs)
      cb(list)
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.$emit("selected", {leader_id: item.id, nickname: item.nickname})
    },
  }
}
</script>

<style scoped>

</style>
