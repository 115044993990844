<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='90%'>
      <el-row :gutter='15'>
        <el-col :span='12'>
          <el-upload
            class='upload-demo'
            :on-change='handleChange'
            :on-success='importSuccess'
            :headers='uploadHeaders'
            :data='postData'
            name='file'
            :show-file-list='false'
            :file-list='file'
            :before-upload='beforeUpload'
            drag
            action='/admin_api/product_dress/import'
            multiple>
            <i class='el-icon-upload'></i>
            <div class='el-upload__text'>将文件拖到此处，或<em>点击上传</em></div>
            <div class='el-upload__tip' slot='tip'></div>
          </el-upload>
        </el-col>
        <el-col :span='12'>
          <div>
            <export-btn :can-export='true' ref='refExportLink' type='primary' lintTitle='下载导入模板'
                        @export='downloadTpl'></export-btn>
          </div>
          <div>
            <tips :data='tipList'></tips>
          </div>

        </el-col>
      </el-row>
      <div>
        <el-row>
          <el-col :span='24'>
            <!--          <div style='margin-bottom: 5px'>-->
            <!--            <span style='font-size: 1.3em;font-weight: bold'>导入数据预览</span>-->
            <!--            <el-link type='primary' @click='showDemo' style='float: right'>查看示例</el-link>-->
            <!--          </div>-->
            <div class='default-table'>
              <el-divider>导入数据预览</el-divider>
              <el-table :data='dataList' max-height='500' border>
                <el-table-column label='序号' type='index' align='center' width='60'></el-table-column>
                <el-table-column label='品牌产品名称' prop='title' align='center' min-width='200'></el-table-column>
                <el-table-column label='颜色/尺码' prop='spec_name' align='center' min-width='100'></el-table-column>
                <el-table-column label='日常价' prop='price' align='center' min-width='100'></el-table-column>
                <el-table-column label='直播价' prop='lb_price' align='center' min-width='100'></el-table-column>
                <el-table-column label='赠品/赠品价值' prop='gift' align='center' min-width='100'></el-table-column>
                <el-table-column label='佣金(%)' prop='commission' align='center' min-width='100'></el-table-column>
                <el-table-column label='保价情况' prop='support_situation' align='center' min-width='100'></el-table-column>
                <el-table-column label='独家情况' prop='special_situation' align='center' min-width='100'></el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
        <br />
        <div style='text-align: center;'>
          <el-button :disabled='loading' :loading='loading' type='primary' size='medium' @click='handleBatchCreate'>提交
          </el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { getToken } from '@/utils/auth'

export default {
  name: 'ImportProductDress',
  components: { ExportBtn },
  props: {
    id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      loading: false,
      dialogTitle: '导入服饰产品库',
      file: [],
      uploadLoading: false,
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      postData: { lb_id: null },
      maxSize: 10,//文件最大的 5 M
      tipList: [
        '第1步，下载模板',
        '第2步，按照模板规范填好数据后，根据提示上传文件',
        '&nbsp;&nbsp;注意：1）二次导入，品牌产品名称做唯一约束，更新数据 2）表格空行会过滤',
        '第3步，预览导入的数据，确认无误后，点击“提交”按钮'
      ],
      dataList: []
    }
  },
  methods: {
    onOpen() {
      this.loading = false
      // this.$notify.info('排期ID：' + this.id)
      this.dataList = []
      // this.showDemo()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    showDemo() {
      this.dataList = []
    },
    async downloadTpl() {
      try {
        let name = `导入模板`
        this.$notify.info(name)
        let queryParams = {
          is_tpl: true
        }
        let response = await this.$api.exportProductDress(queryParams)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    // ======================= 导入相关 =====================
    handleChange(file) {
      this.file = [file]
    },
    beforeUpload(file) {
      //身份凭据
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
      // this.postData['lb_id'] = this.id

      this.uploadLoading = true
      const isLt2M = file.size / 1024 / 1024 < this.maxSize
      if (!isLt2M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`)
        this.uploadLoading = false
      }
      return isLt2M
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('导入成功，请确认无误后，【提交】数据')
        this.dataList = response.data ? response.data.list : []
      } else {
        this.$message.warning(response.message)
      }
    },
    async handleBatchCreate() {
      if (this.dataList.length === 0) {
        this.msgWarn('请先导入文件！！！')
        return false
      }
      this.loading = true
      let { total, ids, failed_rows } = await this.$api.importCreateProductDressInfo(this.dataList)
      // let { total, ids, failed_rows } = await this.$api.batchCreateProductDressInfo(this.dataList)
      this.$notify.success(`导入：${total}条，成功（去重后）：${ids.length}条，失败：${failed_rows.length}条`)

      this.$emit('imported')
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
