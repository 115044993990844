<template>
  <el-button icon='el-icon-copy-document' @click='handleClick'>
    转至{{ aim }}
  </el-button>
</template>
<script>
export default {
  name: 'ProductTransfer',
  props: {
    getProducts: { type: Function }
  },
  computed: {
    aim() {
      return this.$route.name === 'productLibrary' ? '服饰库' : '产品库'
    },
    aim_c_type() {
      return this.$route.name === 'productLibrary' ? 2 : 1
    }
  },
  methods: {
    handleClick: function() {
      const products = this.getProducts()
      if (!Array.isArray(products) || products.length < 1) {
        this.$notify.warning('请选择要转移的商品')
        return
      }
      let dom = ''
      const ids = products.map((_, i) => {
        dom += `<div style='border-bottom: 1px solid #f2f2f2;padding: 4px 0;line-height: 1.2;'>${i + 1}.&nbsp;${_.title}</div>`
        return _.id
      })

      this.$confirm(
        `<div>
                    ${dom}
                    <H3>共计${ids.length}件商品</H3>
                  </div>`,
        `确认转移以下商品至${this.aim}吗?`,
        {
          iconClass: 'el',
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(async () => {
        await this.$api.transferProduct({ ids, c_type: this.aim_c_type })
        this.$message.success('操作成功')
        this.$emit('success')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '放弃转移'
        })
      })
    }
  }
}
</script>
<style lang='scss'>
.product-transfer-container {

}
</style>
