<template>
  <el-dropdown @click='handleState(stateOptions[stateActive].value)' @command='commandBtn'
               split-button
               :type='stateOptions[stateActive].type '
  >
    {{ stateOptions[stateActive].label }}

    <el-dropdown-menu slot='dropdown'>
      <el-dropdown-item :command='index' v-for='(item,index) in stateOptions' :key='item.value'
                        style='width:80px;height: 30px;font-size: 14px;line-height: 30px' :style="{backgroundColor:item.color_value}">
        <i :class='item.icon'></i> {{ item.label }}
        <br>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  name: 'StatusDropdown',
  props: {
    getProducts: { type: Function }
  },
  data() {
    return {
      stateOptions: [
        { label: '标品', value: 1, color_value: '#D8FCC0' },
        { label: '非标品', value: 2, color_value: '#E9EDFA' }
      ],
      stateActive: 0

    }
  },
  methods: {
    commandBtn(val) {
      this.stateActive = val
      this.handleState()
    },
    async handleState() {
      const products = this.getProducts()
      if (!Array.isArray(products) || products.length < 1) {
        this.$notify.warning('请选择要转移的商品')
        return
      }
      let dom = ''
      const ids = products.map((_, i) => {
        dom += `<div style='border-bottom: 1px solid #f2f2f2;padding: 4px 0;line-height: 1.2;'>${i + 1}.&nbsp;${_.title}</div>`
        return _.id
      })
      let data = this.stateOptions[this.stateActive]
      this.$confirm(
          `<div>
                    ${dom}
                    <H3>共计${ids.length}件商品</H3>
                  </div>`,
          `确认以下商品改为${data.label}吗?`,
          {
            iconClass: 'el',
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(async () => {
        await this.$api.batchSetStandardGoods({ ids, wares_standard_is: data.value })
        this.$message.success('操作成功')
        this.$emit('success')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '放弃更改'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
