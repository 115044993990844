<template>
  <div class='box_content'>
    <template v-if='dataList.length>0'>
      <el-card class='card' v-for='(item) in dataList' :key='item.id' shadow='hover'>
        <el-skeleton animated :loading='loading'>
          <template slot='template'>
            <el-skeleton-item variant='image' style='width: 240px; height: 240px;'/>
            <div style='padding: 14px;'>
              <el-skeleton-item variant='p' style='width: 50%'/>
              <div style='display: flex; align-items: center;'>
                <el-skeleton-item variant='text' style='margin-right: 16px;'/>
                <el-skeleton-item variant='text' style='width: 30%;'/>
              </div>
            </div>
          </template>
          <template>
            <product-image style='height: 100px;margin: 0 auto' :image-url='item.cover_url'/>

            <div class='card_content'>
              <br>
              <span>产品/品牌：{{ item.title }}</span>
              <!--          <span>品牌：{{ item.brand_name || '' }}</span>-->
              <span>颜色/尺码：{{ item.spec_name || '' }} </span>
              <span>日常价：<a>{{ item.price || '' }}</a> </span>
              <span>直播价：<a>{{ item.lb_price || '' }}</a> </span>
              <span>赠品/赠品价值：{{ item.gift || '' }} </span>
              <span>佣金(%)：<a>{{ item.commission ? item.commission : '' || '暂无' }}</a></span>
              <span>保价情况：<a>{{ item.support_situation || '' }}</a> </span>
              <span>负责人：<a>{{ item.leader_name || '' }}</a> </span>
              <span>是否标品：<a>{{ Number(item.wares_standard_is) === 1 ? '标品' : '非标品' || '' }}</a> </span>

              <div style='width: 100%;display: flex'>
                <el-button type='text' icon='el-icon-edit' v-if="userPermissions.indexOf('product_dress_edit')>-1"
                           @click='handleEdit(item)'>修改
                </el-button>
                <el-button icon='el-icon-shopping-cart-2' type='text' @click='handleJoinLive(item)'>添加选品
                </el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(item)'
                           v-if="userPermissions.indexOf('product_dress_delete')>-1">删除
                </el-button>
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-card>
    </template>
    <span v-else style='font-size: 18px;margin-top: 10%'>
                     o(╥﹏╥)o
                        <br/>
                        <span>暂无数据</span>
        </span>
  </div>

</template>

<script>
import ProductImage from '@/pages/lb/product/ProductImage'
import { mapGetters } from 'vuex'

export default {
  name: 'DressOldCard',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: { ProductImage },
  props: {
    dataList: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    handleEdit(row) {
      this.$emit('handleDressStore', row.id)
    },
    handleJoinLive(row) {
      this.$emit('handleJoinLive', row)
    },
    handleDelete(row) {
      this.$emit('handleDelete', row)
    },
    handleDetails(row) {
      this.$emit('handleDetails', row)
    }
  },
  mounted() {
    console.log(this.dataList)
  }
}

</script>

<style lang='scss' scoped>
.card_content {
  > span {
    display: block;
    font-size: 14px;
    margin: 4px 0;
    color: #606266;

    > a {
      color: #c92b60;
    }
  }

  > a {
    font-size: 13px;
    margin: 2px 0;

  }
}

.box_content {
  width: 100%;
  height: auto;
  //border: 1px solid red;
  //border: 1px solid red;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  justify-content: center;
  //flex-direction: column;

  > .card {
    cursor: pointer;
    width: calc((100% / 5) - 50px);
    min-width: 300px;
    margin: 10px auto;
    //max-height: 280px;
    float: left;
    padding-bottom: 10px;
  }
}

@media(max-width: 1700px) {
  .box_content {
    width: 100%;
    //min-width: 1080px;
    //overflow: scroll;

    > .card {
      width: calc((100% / 5) - 26px);
      min-width: 180px;
      margin: 10px auto;


    }
  }
}


</style>
<style>
.popperClass {
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
