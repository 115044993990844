<template>
  <div>
    <!--    <div style="text-align: center">-->
    <!--      <el-checkbox >-->
    <!--        <div>关联有 <span class="product-total">{{ productTotal }}</span> 个产品</div>-->
    <!--      </el-checkbox>-->
    <!--    </div>-->
    <el-form ref="elForm" :model="formData" label-width="80" size="mini" :rules="rules" inline>
      <el-form-item label="负责人" prop="leader_id">

        <ProductLeaderSelect ref="refProductLeaderSelect" :leader-id.sync="formData.leader_id"
                             @selected="selectedLeader"/>
      </el-form-item>
      <el-form-item label="交接人" prop="new_leader_id">
        <UserSelect :leader-id.sync="formData.new_leader_id" @selected="selectedNewLeader"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="handleConfirm()" :disabled="loadingCommit" :loading="loadingCommit"
                   icon="el-icon-connection">产品交接
        </el-button>
      </el-form-item>
    </el-form>
    <div class="default-table">
      <!--              负责的产品-->
      <el-table ref="multipleTable" :data="dataList"
                @selection-change="handleSelectionChange"
                @sort-change="changeTableSort" height="400">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column label="商品名称" prop="title" width="260" show-overflow-tooltip></el-table-column>
        <el-table-column label="类目" prop="category_id" min-width="100" align="center" sortable="custom">
          <template slot-scope="{row}">
            <span>{{ row.category || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="规格" prop="spec_name" align="center" min-width="200"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="公司" prop="company_name" align="center" min-width="120" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span>{{ row.company_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="负责人" prop="leader_id" align="center" min-width="100" sortable="custom">
          <template slot-scope="{row}">
            <span>{{ row.leader_name || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at" align="center" min-width="140" sortable="custom"></el-table-column>
        <el-table-column label="修改时间" prop="updated_at" align="center" min-width="140" sortable="custom"></el-table-column>
      </el-table>
      <el-row>
        <el-col :span="12">
          <div>
            <Tips :data="tipsList"/>
          </div>
        </el-col>
        <el-col :span="12">
          <pagination :hidden="total===0"
                      :background="true"
                      :total.sync="total"
                      :page.sync="pageData.current_page"
                      :limit.sync="pageData.page_size"
                      @pagination="getList"/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ProductLeaderSelect from "@/pages/lb/components/ProductLeaderSelect";
import UserSelect from "@/pages/lb/components/UserSelect";

export default {
  name: "ProductHandOver",
  components: {UserSelect, ProductLeaderSelect},
  data() {
    return {
      tipsList:[
        "勾选要交接的产品，若不勾选，则会认为需要全部交接！！！",
      ],
      formData: {
        leader_id: null,
        leader_name: null,
        new_leader_id: null,
        new_leader_name: null,
        product_ids: []
      },
      rules: {
        leader_id: {required: true, message: '请选择原负责人',},
        new_leader_id: {required: true, message: '请选择交接人',},
      },
      productTotal: 0,
      selectTotal: 0,
      dataList: [],//负责人的产品列表
      pageData: {page_size: 10, current_page: 1},
      orderSort: {id: 'desc'},
      total: 0,
      multipleSelection: [],
      productIds: [],//选中的产品ID集合
      loadingCommit: false,
    }
  },
  methods: {
    init() {
      this.loadingCommit = false
      this.pageData.current_page = 1
      this.productIds = []
      this.dataList = []
      this.formData = {
        leader_id: null,
        leader_name: null,
        new_leader_id: null,
        new_leader_name: null,
        product_ids: []
      }
      //刷新原负责人
      this.$refs['refProductLeaderSelect'].getOptions()
    },
    selectedLeader(item) {
      // console.log(item)
      this.formData.leader_name = item.label
      this.productTotal = item.total || 0
      this.handleQuery()
    },
    selectedNewLeader(item) {
      this.formData.new_leader_name = item.label
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) {
          this.$message.error('数据验证失败，请检查必填项数据！')
          return
        }
        this.formData.product_ids = this.productIds;
        let tipMsg = ''
        if (this.formData.product_ids.length > 0) {
          tipMsg = `此操作将会把勾选产品(${this.formData.product_ids.length}个)，交接给『${this.formData.new_leader_name}』, 是否继续?`
        } else {
          tipMsg = `未勾选产品，将会把【『${this.formData.leader_name}』负责的全部产品】交接给『${this.formData.new_leader_name}』, 是否继续?`
        }
        this.$confirm(tipMsg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.onsubmit(this.formData)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      })
    },

    async onsubmit(formData) {
      this.loadingCommit = true
      let {total} = await this.$api.productHandOver(formData)
      if (total) {
        this.$notify.success(`交接成功:${total}个产品`)
        this.formData.product_ids = []
        //刷新原负责人
        this.$refs['refProductLeaderSelect'].getOptions()

        this.handleQuery()
        setTimeout(() => {
          this.loadingCommit = false
        }, 500)
      }
    },

    async getProductLeaderAbout() {
      let {product_total, select_total} = await this.$api.productLeaderAbout({leader_id: this.formData.leader_id})
      this.productTotal = product_total
      this.selectTotal = select_total
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      this.productIds = val.map((item) => {
        return item.id
      })
    },
    async getList() {
      this.dataList = []
      let searchCondition = {}
      if (this.formData.leader_id)
        searchCondition['leader_id'] = this.formData.leader_id
      Object.assign(searchCondition, this.pageData, {order: this.orderSort})
      let {list, pages} = await this.$api.getProductList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.handleQuery()
    },

  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
.product-total {
  color: #ff0000;
}

</style>