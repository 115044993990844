<template>
  <el-dialog fullscreen v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'>
    <el-row>
      <el-col :span='24'>
        <el-form :model='searchData' inline>
          <!--              <el-form-item label="负责人" prop="leader_id">-->
          <!--                <UserSelect :leader-id.sync="searchData.leader_id"  @selected="selectedLeader"/>-->
          <!--              </el-form-item>-->
          <el-form-item label=''>
            <el-date-picker
                v-model='searchData.ym'
                type='month'
                value-format='yyyy-MM'
                format='yyyy年MM月'
                @change="handleQuery"
                placeholder='选择月份' style='width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <artist-search-custom :type='``' @handleSelect='selectArtist' :show-first-group='false'
                                  style='width: 100%'/>
          </el-form-item>
          <el-form-item label='发起人'>
            <el-input v-model='searchData.leader_name' clearable placeholder='发起人：支持模糊搜索'></el-input>
          </el-form-item>
          <el-form-item label='状态'>
            <el-select v-model='searchData.progress_status' style='width: 120px' clearable>
              <el-option :label='option.label' :value='option.value' v-for='(option,key) in progressStatusOptions'
                         :key='key'
                         :style='`text-align: center;border-radius: 3px;margin:1px;border: #7d8086 1px solid;background-color:${option.color_value}`'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleQuery' icon='el-icon-search'>查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>选品表</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleKolJoinBatch">批量添加</el-button>
          </div>
          <div class='default-table'>
            <el-table :data='KolDataList' highlight-current-row border
                      @selection-change="handleKolSelectionChange"
                      :default-sort="{prop: 'deadline', order: 'ascending'}">
              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column type='index' label='序号' width='65' align='center'></el-table-column>
              <el-table-column label='日期' prop='date' align='center' min-width='120'></el-table-column>
              <el-table-column label='红人' prop='nickname' align='center' min-width='120'
                               ></el-table-column>
              <el-table-column label='平台' prop='platform_code' align='center' min-width='140' >
                <template slot-scope='{row}'>
                  <span>{{ row.platform_name || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label='链接' prop='start_at' align='center' min-width='260'
                               show-overflow-tooltip>
                <template slot-scope='{row}'>
                  <el-link @click="routerLink('kol',row.id)">
                    {{ row.date }} 【{{ row.nickname }}】{{ row.platform_name }} 选品
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label='操作' min-width='80' align='center' fixed='right'>
                <template slot-scope='{row}'>
                  <el-button type='text' @click='handleKolJoin(row)' icon='el-icon-shopping-bag-1'
                  >添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
<!--            <m-pagination :total.sync='KolPagingData.total' :limit.sync='KolPagingData.page_size'-->
<!--                          :page.sync='KolPagingData.current_page'-->
<!--                          @pagination='getKolList'/>-->
          </div>

        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>直播表</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="handleKolJoinBatch">批量添加</el-button>
          </div>
          <div class='default-table'>
            <el-table :data='LiveDataList' highlight-current-row border
                      @selection-change="handleKolSelectionChange"
                      :default-sort="{prop: 'deadline', order: 'ascending'}">
              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column type='index' label='序号' width='65' align='center'></el-table-column>
              <el-table-column label='日期' prop='date' align='center' min-width='120'></el-table-column>
              <el-table-column label='红人' prop='nickname' align='center' min-width='120'
                               ></el-table-column>
              <el-table-column label='平台' prop='platform_code' align='center' min-width='140' >
                <template slot-scope='{row}'>
                  <span>{{ row.platform_name || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label='链接' prop='start_at' align='center' min-width='260'
                               show-overflow-tooltip>
                <template slot-scope='{row}'>
                  <el-link @click="routerLink('live',row.id)">
                    {{ row.date }} 【{{ row.nickname }}】{{ row.platform_name }} 定品
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label='操作' min-width='80' align='center' fixed='right'>
                <template slot-scope='{row}'>
                  <el-button type='text' @click='handleKolJoin(row)' icon='el-icon-shopping-bag-1'
                  >添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
<!--            <m-pagination :total.sync='LivePagingData.total' :limit.sync='LivePagingData.page_size'-->
<!--                          :page.sync='LivePagingData.current_page'-->
<!--                          @pagination='getLiveList'/>-->
          </div>
        </el-card>
      </el-col>

    </el-row>
  </el-dialog>
</template>

<script>
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'

export default {
  name: 'JoinLive',
  data() {
    return {
      dialogTitle: '产品添加到选品',
      searchData: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      KolDataList: [],
      KolPagingData: {},
      LivePagingData: {},
      KolSelection: [],
      LiveDataList: []
    }
  },
  components: {
    ArtistSearchCustom
  },
  props: {
    productId: {
      type: [Number, String]
    }
  },
  methods: {
    onOpen() {
      this.defaultTime()
    },
    onClose() {
    },

    defaultTime() {
      const end = new Date()
      //年份选择：默认当月

      // this.searchData.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
      this.$set(this.searchData,'ym',end.getFullYear() + '-' + (end.getMonth() + 1))
    },

    selectArtist(selectInfo) {
      // console.log(selectInfo)
      this.searchData.artist_id = selectInfo.artist_id
      this.searchData.nickname = selectInfo.nickname
      this.searchData.group_id = selectInfo.group_id
      this.searchData.group_name = selectInfo.group_name
      this.handleQuery()
    },
    // 处理搜索条件
    handlesearchData() {
      let condition = {}
      if (this.searchData.ym) {
        condition.ym = this.searchData.ym
      }
      if (this.searchData.artist_id) {
        condition.artist_id = this.searchData.artist_id
      }
      if (this.searchData.progress_status) {
        condition.progress_status = this.searchData.progress_status
      }
      if (this.searchData.leader_id) {
        condition.leader_id = this.searchData.leader_id
      }
      if (this.searchData.leader_name) {
        condition.leader_name = this.searchData.leader_name
      }
      return condition
    },
    async getKolList() {
      let searchData = this.handlesearchData()
      let order = { deadline: 'asc' }
      Object.assign(searchData, { type: 1 }, this.pagingData,{order:order})
      let { list } = await this.$api.getLbScheduleMonthList(searchData)
      this.KolDataList = list
      // this.KolPagingData.total = pages.total
      // this.KolPagingData.current_page = pages.current_page
      // this.KolPagingData.page_size = pages.page_size
    },
    async getLiveList() {
      let searchData = this.handlesearchData()
      let order = { deadline: 'asc' }

      Object.assign(searchData, { type: 2 },{order:order})
      let { list } = await this.$api.getLbScheduleMonthList(searchData)
      this.LiveDataList = list
      // this.LivePagingData.total = pages.total
      // this.LivePagingData.current_page = pages.current_page
      // this.LivePagingData.page_size = pages.page_size
    },

    handleQuery() {
      // this.pagingData.current_page = 1
      this.getKolList()
      this.getLiveList()
      this.isInitLoading = false
    },
    handleKolSelectionChange(val) {
      this.KolSelection = val
    },
    async handleKolJoin(row) {
      // let postData = {
      //   lb_id: row.id,
      //   product_ids: [this.productId]
      // }
      let id = await this.$api.appendLbDressSelection(row.id,[this.productId])
      if (id) {
        this.$message.success('添加成功')
      }
    },
    async handleKolJoinBatch() {
      let ids = this.KolSelection.map((i) => {
        return i.id
      })
      for (const item of ids) {
        let id = await this.$api.appendLbDressSelection(item, [this.productId])
        if (id) {
          this.$message.success('添加成功')
        }
      }
    },
    isDing() {
      const ua = window.navigator.userAgent
      return ua.indexOf('DingTalk') !== -1 // true or false
    },
    routerLink(type, id) {
      let name = 'productDressKol'
      if (type === 'kol') {
        name = 'productDressKol'
      } else {
        name = 'productDressLive'
      }

      if (this.isDing()) {
        this.$router.push({
          name: name,
          params: { id: id }
        })
      } else {
        const { href } = this.$router.resolve({
          name: name,
          params: { id: id }
        })
        window.open(href, '_blank')
      }
    }
  }
}
</script>

<style scoped>

</style>
