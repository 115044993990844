<template>
  <div>
    <el-dropdown style='margin-left: 10px' @command='(command) => pptExport(command)'>
      <el-button v-bind='$attrs' type='primary'><i title='单：每个手卡独立一个PPT文件；多：多个手卡同一个PPT文件' class='el-icon-info'></i> 产品手卡<i class='el-icon-arrow-down el-icon--right'></i></el-button>
      <el-dropdown-menu slot='dropdown'>
        <el-dropdown-item command='single'>产品手卡（单）</el-dropdown-item>
        <el-dropdown-item command='multiple'>产品手卡（多）</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { htmlToPptxText } from 'html2pptxgenjs'
import pptxgen from 'pptxgenjs'

export default {
  name: 'ExportPpt',
  props: {
    multipleSelection: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    loopPPt(pres, i) {
      let url = String(i.cover_url).replace('https://tisu-pd.oss-cn-hangzhou.aliyuncs.com/', 'http://image.pblab.com/')
      let slide = pres.addSlide()
      //标题
      slide.addText(`${i.company_name}`, {
        x: 0.1,
        y: 0.1,
        w: '60%',
        h: 0.3,  //高度
        color: '000000',
        fill: { color: 'a6a6a6' },
        align: 'left',
        fontFace: '微软雅黑',
        bold: true,
        fontSize: 18
      })

      let formTitle = [

        {
          title: '商品名称及规格',
          value: i.title + '(' + i.spec_name + ')',
          bold: true,
          color: '000000',
          w: 20,
          TitleCol: '000000'
        },
        {
          title: '店铺评分',
          value: `${i.company_score || '/'}分 `,
          bold: true,
          color: '000000',
          w: 20,
          TitleCol: '000000'
        }
        , {
          title: '费用及佣金',
          value: (i.ad_space_fee || '-') + '/' + (i.online_cmn || '-') + '%',
          bold: false,
          color: '000000',
          w: 30,
          TitleCol: '000000'
        }
        , { title: '日常标价', value: i.price + '元', bold: false, color: '000000', w: 30, TitleCol: '000000' }
        , {
          title: '直播间到手价',
          value: i.lb_price + '元',
          bold: false,
          color: '000000',
          TitleCol: 'e91414',
          w: 30
        },
        {
          title: '赠品',
          value: i.gift,
          bold: false,
          color: '000000',
          TitleCol: 'e91414',
          w: 30
        }
        , {
          title: '是否全网统一机制',
          value: i.unify === 1 ? '是' : '否',
          bold: false,
          color: '000000',
          w: 30,
          TitleCol: '000000'
        }
        , {
          title: '是否有签独家',
          value: `${i.exclusive === 1 ? '是' : '否'}(${i.other_activity})`,
          bold: true,
          color: 'e91414',
          w: 30,
          TitleCol: '000000'
        }
        , {
          title: '是否可签保价1个月',
          value: i.support_value === 1 ? '是' : '否',
          bold: true,
          color: 'e91414',
          w: 30,
          TitleCol: '000000'
        }
        , {
          title: '历史达播销量',
          value: i.sales_volume,
          bold: false,
          color: 'e91414',
          w: 30,
          TitleCol: 'e91414'
        }
        , {
          title: '备注',
          value: i.remark,
          bold: true,
          color: '000000',
          w: 30,
          TitleCol: '000000'
        }
      ]
      let row = []
      const options = {
        valign: 'middle',
        color: '363636',
        align: 'left',
        fontFace: '微软雅黑',
        fontSize: 7.5
      }
      formTitle.forEach((item) => {
        row.push([	// 表格数据
          { text: item.title, options: { ...options, color: item.TitleCol } },
          { text: item.value, options: { ...options, color: item.color, bold: item.bold } }
        ])
      })
      slide.addTable(row, { // 表格默认配置
        x: 0.1,
        y: 0.4,
        w: 40,
        rowH: 0, // 单元格默认高度
        valign: 'middle',
        fontSize: 8,
        color: '666666',
        align: 'center',
        autoPageHeaderRows: { autoPage: true },
        colW: [2.0, 4.5]
        // 表格每一列宽度
      })
      // e9eef4
      slide.addText('亮点卖点（简洁）', {
        x: 0.1,
        y: 2.8,
        h: 0.3,  //高度
        color: '000000',
        align: 'left',
        fontFace: '微软雅黑',
        bold: true,
        fontSize: 12
      })
      let info = htmlToPptxText(i.light_spot, {
        fontFace: '微软雅黑',
        fontSize: 7
      })
      slide.addText(info, {
        x: 0.1,
        y: 3.1,
        w: '60%',
        h: '44%',  //高度
        color: '000000',
        fill: { color: 'f5f7f9' },
        align: 'left',
        fontFace: '微软雅黑',
        fontSize: 8
      })
      //
      slide.addShape(pres.ShapeType.rect, { fill: { color: 'f5f7f9' }, w: 3, h: 3, x: 6.5, y: 0.4 })
      slide.addText('商品图片', {
        x: 7.5,
        y: 0.6,
        h: 0.3,  //高度
        color: '000000',
        align: 'left',
        fontFace: '微软雅黑',
        bold: true,
        fontSize: 16
      })
      slide.addImage({
        path: url,
        w: 2,
        h: 2,
        x: 7.1,
        y: 1.1
      })
      let commodityInfo = i.intro ? i.intro : ''
      slide.addText(commodityInfo, {
        x: 6.2,
        y: 4.2,
        w: '38%',
        h: 'auto',  //高度
        color: '000000',
        fill: { color: 'f5f7f9' },
        align: 'left',
        fontFace: '宋体',
        fontSize: 10
      })
    },

    pptExport(command) {
      if (this.multipleSelection.length > 0) {
        if (command === 'single') {
          let pres = new pptxgen()
          // http://image.pblab.com/tisu-pd-wx/20230602/TM20230602103726_69391.jpeg
          this.multipleSelection.forEach((i) => {
            this.loopPPt(pres, i)
          })
          pres.writeFile({ fileName: '缇苏直播手卡提报.pptx' }).then(() => {
            this.$message.success('手卡导出成功')
          })
        } else {
          // 多个PPT导出
          this.multipleSelection.forEach((i) => {
            let pres = new pptxgen()
            this.loopPPt(pres, i)
            pres.writeFile({ fileName: i.title + '直播手卡提报.pptx' })

          })
        }

      } else {
        this.$message.error('请选择要导出手卡的产品')
      }
    }
  }
}
</script>

<style scoped>

</style>