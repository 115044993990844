<template>
  <div>
    <el-form size='small' inline>
      <el-form-item>
        <el-date-picker
          size='mini'
          v-model='searchCondition.commit_at'
          type='daterange'
          align='right'
          unlink-panels
          range-separator='至'
          start-placeholder='寄样提交开始时间'
          end-placeholder='寄样提交结束日期'
          value-format='yyyy-MM-dd'
          @change='getLogisticList'
          :picker-options='pickerOptions'>
        </el-date-picker>
      </el-form-item>
      <el-form-item label=''>
        <el-input size='mini' v-model='searchCondition.nu' clearable placeholder='请输入物流单号'
                  @change='getLogisticList' />
      </el-form-item>
      <el-form-item>
        <el-button icon='el-icon-search' @click='getLogisticList'
                   size='mini'>查询
        </el-button>
      </el-form-item>
      <el-form-item v-if='hasAuthority("product_specimen_commit")'>
        <product-specimen-save :get-products='getProducts' @success='getLogisticList' />
      </el-form-item>
    </el-form>
    <el-table :data='logisticList'
              border highlight-current-row>
      <el-table-column align='center' label='寄样备注' prop='remark' show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='寄样提交日期' prop='commit_at'
                       show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='寄样数量' prop='qty' show-overflow-tooltip></el-table-column>
      <el-table-column :formatter='$utils.formatterYorN' align='center' label='是否需要退样' prop='need_back'
                       show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='快递' prop='com_label' show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='快递单号' prop='nu' show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='寄样物流状态' prop='_state' show-overflow-tooltip>
        <template slot-scope='{row}'>
          <el-popover
            v-if='row.logistic_data?.length!=0'
            placement='right'
            popper-class='popperClass'
            trigger='click'
            width='400'>
            <div class='popoverTitle'> 物流详情
            </div>
            <logistic-time-line :logisticData='row.logistic_data' v-if='row.logistic_data'></logistic-time-line>
            <el-button slot='reference' size='small'
                       type='text'> {{ row._state }}
            </el-button>
          </el-popover>
          <span v-else>{{ row._state }}</span>
        </template>
      </el-table-column>
      <el-table-column align='center' label='签收日期' prop='sign_time' show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='最后更新时间' prop='updated_at' show-overflow-tooltip></el-table-column>
      <el-table-column align='center' label='操作' width='120'>
        <template slot-scope='scope'>
          <!--          <el-button size='small' style='margin-left: 6px' type='text' @click='handleAdd("edit",scope.row)'>-->
          <!--            修改-->
          <!--          </el-button>-->
          <el-button size='small' style='margin-left: 6px' type='text'
                     v-if='hasAuthority("product_specimen_record_delete")' @click='handleDel(scope.row)'>
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination :background='true' :hidden='logisticTotal === 0' :limit.sync='logisticPageData.page_size'
                :page.sync='logisticPageData.current_page' :total.sync='logisticTotal' @pagination='getLogisticList' />
    <edit-specimen @submitData='handelStore' :visible.sync='dialogStore' :formStore.sync='formStore'></edit-specimen>
  </div>
</template>

<script>
import logisticTimeLine from '@/components/LogisticTimeLine.vue'
import EditSpecimen from '@/pages/lb/product/EditSpecimen.vue'
import ProductSpecimenSave from '../components/ProductSpecimenSave.vue'


export default {
  name: 'SpecimenRecord',
  components: {
    ProductSpecimenSave,
    EditSpecimen,
    logisticTimeLine
  },
  props: {
    id: {
      type: [String, Number]
    },
    product: { type: Object }
  },
  data() {
    return {
      logisticList: [],
      logisticPageData: { page_size: 10, current_page: 1 },
      logisticTotal: 0,
      searchCondition: {
        commit_at: '',
        nu: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      dialogStore: false,
      isEdit: false,
      formStore: {
        nu: null,
        com: null,
        com_label: null,
        qty: 1,
        need_back: null,
        remark: null
      }

    }
  },
  watch: {
    id: {
      handler() {
        this.getLogisticList()
      },
      immediate: true
    }
  },
  methods: {
    getProducts: function() {
      return [this.product]
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.commit_at) {
        cond['commit_at'] = this.searchCondition.commit_at
      }
      if (this.searchCondition.nu) {
        cond['nu'] = this.searchCondition.nu
      }
      cond['product_id'] = this.id
      return cond
    },
    async getLogisticList() {
      let params = {
        ...this.handleSearchCondition(), ...this.logisticPageData
      }
      let { list, pages } = await this.$api.getProductSpecimenRecord(params)
      this.logisticList = list || []
      this.logisticPageData.current_page = pages.current_page || 1
      this.logisticPageData.page_size = pages.page_size || 1
      this.logisticTotal = pages.total
    },
    handleAdd(type, row = null) {
      if (type === 'edit') {
        this.isEdit = true
        this.formStore = row
      } else {
        this.isEdit = false
        this.formStore = {
          nu: null,
          com: null,
          com_label: null,
          qty: 1,
          need_back: null,
          remark: null
        }
      }

      this.dialogStore = true
    },
    async handleDel(row) {
      this.$confirm('是否确认删除"' + row.com_label + '"的快递数据?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.deleteLbProductSpecimenCommit({ id: row.id })
        if (id) {
          this.$message.success('删除成功！')
          await this.getLogisticList()
        } else {
          this.$message.error('未知错误,请联系管理员')
        }
      }).catch(function() {
      })

    },
    async handelStore() {
      let { nu, com, qty, need_back, remark, com_label } = this.formStore
      let data = {
        nu: nu,
        com: com,
        com_label: com_label,
        remark: remark,
        items: [{
          product_id: this.id,
          qty: qty,
          need_back: need_back
        }]
      }
      let id = await this.$api.addLbProductSpecimenCommit(data)
      if (id) {
        this.$message.success('新增成功！')
        this.dialogStore = false
        await this.getLogisticList()
      } else {
        this.$message.error('未知错误,请联系管理员')
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.form-card {
  border: #DCDFE6 1px solid;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.rate-txt {
  color: #F56C6C;
}

.popoverTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;

  > span {
    font-size: 16px;
    float: right;
    color: red;
    cursor: pointer;
  }
}

.popperClass {
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
