<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @open='onOpen' width="90%" height="90%" center>
    <el-skeleton animated :loading='false'>
      <template slot='template'>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-skeleton-item variant='image' style='width: 480px; height: 480px;'/>
          </el-col>
          <el-col :span="15">
            <div class="el-skeleton">
              <el-skeleton-item variant='p' style='width: 50%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 100%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 100%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 20%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 80%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 80%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 60%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 80%'/>
              <br/>
              <el-skeleton-item variant='p' style='width: 100%'/>
            </div>
          </el-col>
        </el-row>
      </template>
      <template>
        <el-row :gutter="20">
          <el-col :span="8" class="left_box">
            <product-image style='min-height: 480px;margin: 0 auto;width: 100%;'
                           :radius="true"
                           :image-url='rowDetails.cover_url'/>
            <div class="info">
              {{ rowDetails.intro }}
            </div>
          </el-col>

          <el-col :offset="1" :span="15">
            <div class="card_content">
              <el-row>
                <el-col :span="24" :title="rowDetails.title"><b>{{ rowDetails.title }}</b></el-col>
              </el-row>
              <el-row class="card_descriptions">
                <el-col :title="rowDetails.brand_name" :span="6" style="text-align: left">
                  <span>{{ rowDetails.brand_name || '/' }}</span>
                  <a>品牌</a>
                </el-col>
                <el-col :title="rowDetails.spec_name"  :span="6">
                  <span>{{ rowDetails.spec_name || '/' }} </span>
                  <a>颜色/尺码</a>
                </el-col>
                <el-col :title=" String(rowDetails.platform_name)"  :span="6">
                  <span>{{ String(rowDetails.platform_name) || '/' }} </span>
                  <a>可播平台</a>
                </el-col>

              </el-row>
              <el-row style="margin-top: 14px   ">
                <el-col>
                  赠品：{{ rowDetails.gift || '/' }}
                </el-col>
              </el-row>
              <el-row :gutter="20" style="margin-top: 14px ">
                <el-col class="tags" :span="9">
                  <span>佣金(%)：<a>{{ rowDetails.commission ? rowDetails.commission : '' || '暂无' }}</a></span>
                </el-col>
                <el-col class="tags" :span="9" >
                  <span :title="rowDetails.support_situation">保价情况：<a>{{ rowDetails.support_situation || '' }}</a></span>
                </el-col>
              </el-row>
              <el-row style="margin-top: 14px ">
                <el-col :span="5">负责人：{{ rowDetails.leader_name || '/' }}</el-col>
                <el-col :span="10">备注：{{ rowDetails.remark || '' }}</el-col>
              </el-row>
              <el-row style="margin-top: 14px   ">
                <el-col :span="18" class="price_box">
                  <div class="price"><span>日常价￥</span>{{ rowDetails.price || '' }}</div>
                  <div class="lb_price"><span>直播价￥</span>{{ rowDetails.lb_price || '' }}</div>
                </el-col>
              </el-row>

            </div>
          </el-col>

        </el-row>
      </template>
    </el-skeleton>
  </el-dialog>
</template>

<script>

import ProductImage from '@/pages/lb/product/ProductImage'
import { mapGetters } from 'vuex'


export default {
  name: 'DressDetails',
  components: { ProductImage },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {}
  },
  props: {
    rowDetails: {
      type: Object
    }
  },
  methods: {
    onOpen() {

    },
    beforeClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: 0;

  .el-dialog__headerbtn {
    z-index: 9;
  }
}

.left_box {

  .info {
    margin-top: 10px;
    color: $--color-text-secondary;
  }
}

//
.el-skeleton {
  > * {
    margin: 10px 0;
  }
}

.card_content {
  > * > * > b {
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  .card_descriptions {
    margin-top: 20px;
    height: 40px;

    > * {
      border-right: 1px solid $--border-base-color;
      height: 40px;
      text-align: center;
      color: $--color-text-secondary;

      > span {
        color: $--color-text-primary;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }

      > a {
        display: block;
        margin-top: 10px;
      }
    }
  }

  .tags {
    > span {
      background-color: $--background-color-extralight;
      color: $--color-text-secondary;
      padding: 6px 10px;
      border-radius: 4px;
      display: block;
      height: 30px;
      line-height: 30px;

      > a {
        color: $--color-pure-red;
      }

      > span {
        color: $--color-text-primary;
      }
    }
  }

  .price_box {
    border: 1px solid $--color-pure-red;
    height: 32px;
    text-align: center;
    border-radius: 6px;
    display: flex;
    color: $--color-pure-red;

    .price, .lb_price {
      width: 50%;
      height: 100%;
      line-height: 32px;
      //border: 1px solid red;
      font-size: 20px;

      > span {

        font-size: 16px
      }
    }

    .price {
      color: $--color-pure-red;
    }

    .lb_price {
      color: #FFFFFF;
      background-color: $--color-pure-red;
    }
  }

  .buttons {
    > * {
      margin-right: 20px;
      color: black;
      width: 60px;
      font-size: 14px;
      padding: 4px;
      text-align: left;
    }
  }

  .light_spot {
    max-height: 320px;
    background-color: $--background-color-extralight;
    border-radius: 10px;

    > * {
      padding: 0 10px;
    }

    .light_title {
      font-size: 20px;
      font-weight: bold;
      padding: 10px;

    }

    .light_content {
      max-height: calc(320px - 42px);
      overflow: scroll;
      border-radius: 4px;
    }
  }
}
</style>
