<template>
  <div>
    <el-button  v-bind='$attrs' icon='el-icon-download'  @click='exportPpt' :loading='loading' :disabled='loading'>
    产品手卡
    </el-button>
  </div>
</template>

<script>
import FileSaver from 'file-saver'

export default {
  name: 'ExportProductPpt',
  props: {
    multipleSelection: {
      type: Array
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async exportPpt() {
      let title = '缇苏产品手卡提报'
      let isFileSaverSupported = !!new Blob
      if (isFileSaverSupported) {
        let ids = this.multipleSelection.map((item) => {
          return item.id
        })
        if (ids.length > 0) {
          this.loading = true
          let response = await this.$api.exportProductPpt({ ids: ids })
          let data = response.data
          let contentType = response.headers['content-type']
          let blob = new Blob([data], {
            type: contentType
          })

          FileSaver.saveAs(blob, decodeURI(encodeURI(title) + '.pptx'), { autoBom: true })
          this.$message.success("导出成功")
          setTimeout(()=>{
            this.loading = false
          },500)

        } else {
          this.msgWarn('请先选择导出的产品')
        }

      } else {
        this.$message.warning('浏览器不支持导出文件')
      }
    }
  }
}
</script>

<style scoped>

</style>