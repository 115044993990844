<template>
  <div>
    <el-dialog ref='elForm' v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='1100px'>
      <el-row :gutter='15'>
        <el-col :span='8'>
          <el-table :data='dataList' max-height='600' :show-header='false' highlight-current-row>
            <el-table-column label='日志'>
              <template slot-scope='{row}'>
                <span>{{ row.op_name }}</span>
                于
                <span>{{ row.created_at }}</span>

                进行【
                <el-link type='primary' @click='showLogDetail(row)'>{{ row.op_type_alias }}</el-link>
                】操作

              </template>
            </el-table-column>
            <!--        <el-table-column label="操作时间" prop="created_at" width="200"></el-table-column>-->
            <!--        <el-table-column label="操作类型" prop="op_type_alias" width="120"></el-table-column>-->
            <!--        <el-table-column label="操作日志" prop="msg" width="120"></el-table-column>-->
            <!--        <el-table-column label="操作">-->
            <!--          <el-button>查看详情</el-button>-->
            <!--        </el-table-column>-->
          </el-table>
          <div style='text-align: left'>
            <pagination :hidden='total===0'
                        :background='true'
                        :total.sync='total'
                        :page.sync='pageData.current_page'
                        :limit.sync='pageData.page_size'
                        @pagination='getList' />
          </div>
        </el-col>
        <el-col :span='16'>
          <!--          <p style='padding: 10px'>-->
          <!--            {{ logMsg }}-->
          <!--          </p>-->
          <div v-if='logMsg' class='log-detail'>
            <div v-for='(val,field) in logMsg' :key='field'>
              <span>{{ field }}</span>：
              <span>{{ val }}</span>
            </div>
          </div>

        </el-col>
      </el-row>

    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApeTable from '@/components/ApeTable'

export default {
  name: 'ProductLog',
  components: { ApeTable },
  props: {
    infoId: {
      type: [Number, String]
    }
  },
  watch: {
    infoId: {
      immediate: true,
      handler(val) {
        this.searchCondition.info_id = val

      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `产品日志`
    },
    logMsg() {
      let msg = this.row.msg || ''
      if (msg.indexOf('{') > -1) {
        return JSON.parse(msg)
      }
      return null
    }
  },
  data() {
    return {
      loadingStatus: false,
      searchCondition: { op_type: null, info_id: null },
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      row: {}
    }
  },
  methods: {
    onOpen() {
      this.getList()
    },
    onClose() {
      this.dataList = []
      // this.$refs['elForm'].resetFields()
      // this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },

    async getList() {
      this.row = {}
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getProductLog(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
      })
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.info_id) {
        condition.info_id = this.searchCondition.info_id
      }

      if (this.searchCondition.op_type) {
        condition.op_type = this.searchCondition.op_type
      }

      return condition
    },
    showLogDetail(row) {
      // console.log(row)
      this.row = row
    }
  }
}
</script>

<style scoped>
.log-detail {
  padding: 10px;
  border: #ff3176 1px dashed;
}
</style>