<template>
  <el-dialog
      :close-on-click-modal='false' :destroy-on-close='true'
      :show-close='loadingEnd'
      v-bind="$attrs"
      width='50%'
      @close='initDown'
      @open="open"
      v-on="$listeners"
  >
    <!--          :visible.sync='downloadLoading'-->
    <div class='loadingBox'>
      <div class='left'>
        <video :src='require(`@/assets/loading/bg.mp4`)' auto-rotate='false'
               autoplay='true'
               crossorigin='anonymous'
               height='80%' loop='true' muted='true' playsinline='true'
               t7-video-player-type='inline' webkit-playsinline='true' width='80%'
               x5-playsinline='true'></video>
      </div>
      <div class='right'>
        <el-progress :color='colors' :percentage='percentage>100? 100 : percentage' :width='250'
                     style='margin-top: 30px'
                     type='dashboard'></el-progress>
      </div>
      <br/>

    </div>
    <div class='bottom'>
      <h1>
        {{ loadingEnd ? `内容导出成功，共计【${downDataList.length}】条数据` : '内容导出中，请勿刷新或关闭窗口' }}
      </h1>
    </div>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx-js-style'
import snow from '@/assets/festival/snow.png'

export default {
  name: 'exportDiag',
  data() {
    return {
      loadingEnd: false,
      colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      percentage: 0,
      downDataList: [],
      snow: snow,
      pageInfo: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      }
    }
  },
  props: {
    column: {
      type: Array,
      default() {
        return []
      }
    },
    fileName: {
      type: String,
      default() {
        return '列表下载'
      }
    },
    listApi: {
      type: String,
      default() {
        return 'getDressBrandList'
      }
    },
    titleColor: {
      type: Object,
      default() {
        return {
          fgColor: 'fce2eb',
          fontColor: '000000',
          fontSize: 12
        }
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    open() {
      this.exportExcel()
    },
    initDown() {
      this.loadingEnd = false
      this.percentage = 0
      this.downDataList = []
    },
    async exportExcel() {
      this.downloadLoading = true
      this.exportId = null
      await this.getAllData()
    },
    async getAllData() {
      let pageData = {
        page_size: 100,
        current_page: this.pageInfo.current_page,
        total: this.pageInfo.total
      }

      await this.getDownList(pageData)
      if (this.downDataList.length != 0 && this.downDataList.length === pageData.total) {
        // const xlsxParam = { raw: false } //转换成excel时，使用原始的格式
        /* 生成工作表 */
        const workbook = XLSX.utils.book_new()
        let header = this.column.map((i) => {
          return !i.hide ? i.label : false
        }).filter(Boolean)
        let data = []
        this.downDataList.forEach((item) => {
          let row = {}
          this.column.forEach((j) => {
            if (!j.hide) {
              row[j.label] = item[j.prop]
            }
          })
          data.push(row)
        })
        // console.log(data,data.reverse())
        let ws = XLSX.utils.json_to_sheet(data, { header: header })
        ws = this.worksheetStyle(ws)
        XLSX.utils.book_append_sheet(workbook, ws, this.fileName)
        XLSX.writeFile(workbook, `${this.fileName}.xlsx`)
        this.loadingEnd = true
        setTimeout(() => {
          this.downloadLoading = false
          this.$emit('close')
        }, 2000)
      }
    },
    worksheetStyle: function(worksheet) {
      // 表样式设置
      const colsLength = this.column.length - 1
      let cols = [] // 设置每列的宽度
      let hpxs = []
      // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
      //hpx 高度
      for (let i = 0; i <= colsLength; i++) {
        let col = {}
        let hpx = {}
        col.wch = this.column[i].wch || 20
        hpx.hpx = this.column[i].hpx || 30
        cols.push(col)
        hpxs.push(hpx)
      }
      worksheet['!cols'] = cols // 设置列宽信息到工作表
      worksheet['!rows'] = hpxs // 设置列高信息到工作表
      //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
      Object.keys(worksheet).forEach((key) => {
        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
          let data = key.split('', 3)
          if (data[1] == 1 && !(data[2])) {
            worksheet[key].s = {
              font: {
                sz: this.titleColor.fontSize,
                name: 'Microsoft YaHei',
                color: { rgb: this.titleColor.fontColor }
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
              },
              fill: {
                fgColor: { rgb: this.titleColor.fgColor }
              }
            }
            worksheet[key].t = 's'
          } else {
            worksheet[key].s = {
              font: {
                sz: '12'
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
              }
            }
            worksheet[key].t = 'n'
          }
        }
      })

      return worksheet
    },

    async getDownList(pageData) {
      let searchCond = { current_page: pageData.current_page, page_size: pageData.page_size, ...this.searchCondition }
      let list = []
      let page_info = {}

      let data = await this.$api[this.listApi](Object.assign(searchCond))
      list = data.list
      page_info = data.pages
      this.downDataList = [...this.downDataList, ...list]
      pageData.total = page_info.total
      pageData.current_page = page_info.current_page

      this.percentage = Math.round((pageData.current_page / (page_info.total / 100)) * 100)
      if (this.downDataList.length < pageData.total) {
        pageData.current_page++
        await this.getDownList(pageData)
      } else {
        // 数据加载完毕，通知后台记录导出行为
        //记录导出信息
        // await this.endRecordExport()
        return true
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.loadingBox {
  width: 80%;
  margin: 0 auto;
  display: flex;

}

.bottom {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: #ff3176;
}
</style>
