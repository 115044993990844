<template>
  <div>
    <el-button type="warning" icon="el-icon-connection" @click="dialogVisible=true">交接</el-button>
    <el-dialog
        title="工作交接"
        :visible.sync="dialogVisible"
        @open="onOpen"
        @close="onClose"
        width="90%">
      <div>
        <el-card style="margin-bottom: 20px">
          <div>
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
              <el-tab-pane label="产品" name="first">
                <ProductHandOver/>

              </el-tab-pane>
              <el-tab-pane label="选品" name="second">
                <SelectionHandOver/>

              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProductLeaderSelect from "@/pages/lb/components/ProductLeaderSelect";
import LeaderSearch from "@/pages/lb/components/LeaderSearch";
import UserSelect from "@/pages/lb/components/UserSelect";
import SelectionHandOver from "@/pages/lb/product/SelectionHandOver";
import ProductHandOver from "@/pages/lb/product/ProductHandOver";

export default {
  name: "WorkHandover",
  components: {ProductHandOver, SelectionHandOver, UserSelect, LeaderSearch, ProductLeaderSelect},
  data() {
    return {
      activeName:'first',
      dialogVisible: false
    }
  },
  methods: {
    onOpen() {
    },
    onClose() {
      this.$emit('refresh')
    },
    handleClick(){},
  }
}
</script>

<style scoped>

</style>