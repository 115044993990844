<template>
  <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle' width='780px'
             center>
    <el-row :gutter='20'>
      <el-col :span='16'>
        <el-form ref='formData' :model='formData' :rules='rules' label-poionsit='right'
                 label-width='120px'>
          <el-form-item label='可播平台' prop='platform_code'>
            <PlatformSelect @saved='handleSelectPlatform' :range-all='true' v-model='formData.platform_code'
                            placeholder='请选择可播平台' :default-code='formData.platform_code'/>
          </el-form-item>
          <el-form-item label='产品/品牌' prop='title'>
            <el-input v-model='formData.title' maxlength='100' show-word-limit clearable></el-input>
          </el-form-item>
          <!--          <el-form-item label='品牌' prop='brand_name'>-->
          <!--            <el-input v-model='formData.brand_name' maxlength='50' show-word-limit clearable></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label='颜色' prop='spec_color'>-->
          <!--            <el-input v-model='formData.spec_color' maxlength='100' show-word-limit clearable></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label='尺码/颜色' prop='spec_name'>
            <el-input v-model='formData.spec_name' maxlength='100' show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label='日常价(元)' prop='price'>
            <el-input v-model='formData.price' maxlength='200' show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label='直播价(元)' prop='lb_price'>
            <el-input v-model='formData.lb_price' maxlength='200' show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label='赠品/赠品价值' prop='gift'>
            <el-input v-model='formData.gift' maxlength='200' show-word-limit clearable></el-input>
          </el-form-item>
          <el-form-item label='佣金(%)' prop='commission'>
            <el-input v-model='formData.commission' placeholder='佣金(%)' maxlength='200' show-word-limit
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label='保价情况' prop='support_value'>
            <el-input v-model='formData.support_situation' maxlength='200' show-word-limit clearable></el-input>
          </el-form-item>

          <el-form-item label='独家情况' prop='exclusive'>
            <el-input v-model='formData.special_situation' maxlength='200' show-word-limit clearable></el-input>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span='6'>
        <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                     @handleUploadRemove='handleUploadRemove'
                     @handleUploadSuccess='handleUploadSuccess'
                     uploadTip='请上传图片，最多上传1份。大小限制2M文件'></ApeUploader>
      </el-col>
    </el-row>
    <span slot='footer' class='dialog-footer'>
        <el-button size='small' @click='beforeClose'>取消</el-button>
        <el-button size='small' type='primary'
                   @click='handleStore'>{{ formData.id ? '保存' : '添加' }}</el-button>
        <el-button size='small' type='warning' @click='handleCopy' icon='el-icon-document-copy'>复制</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ApeUploader from '@/components/ApeUploader'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'

const rules = {
  title: [
    { required: true, message: '请输入品牌名', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 100 个字符', trigger: 'blur' }
  ]
}
export default {
  name: 'AddDress',
  components: {
    ApeUploader, PlatformSelect
  },
  data() {
    return {
      rules: rules,
      uploadFileList: [],
      selectDress: false,
      formData: { cover: null, cover_url: null }
      // dialogTitle: ''
    }
  },
  computed: {
    dialogTitle() {
      return `服饰库-${!this.formData.id ? '添加' : '修改'}产品信息`
    }
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return null
      }
    }
    // formStore: {
    //   type: Object
    // }
    // custom_type: {
    //   type: String
    // }
  },
  methods: {
    onOpen() {
      this.formData = {}
      this.formData.id = this.id
      // this.dialogTitle = `服饰库-${!this.id ? '添加' : '修改'}品牌信息`
      // this.dialogTitle = `服饰库-${!this.id ? '添加' : '修改'}品牌信息`
      this.uploadFileList = []
      this.formData = { cover: null, cover_url: null }
      if (this.id) {
        this.getInfo(this.id)
      }
      //
      // this.formData = this.formStore
    },
    onClose() {
      // this.$refs['elForm'].resetFields()
      // this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    async getInfo(id) {
      let { info } = await this.$api.getProductDressInfo(id)
      this.formData = info
      this.uploadFileList = this.formData.cover_url ? [{ url: this.formData.cover_url }] : []
    },
    //关闭弹窗
    beforeClose() {
      this.$emit('update:visible', false)
      this.uploadFileList = []
    }
    ,
    // 图片删除回调
    handleUploadRemove() {
      this.formData.cover_url = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    }
    ,
    handleUploadSuccess(file) {
      this.formData.cover = file.id
      this.formData.cover_url = file.full_path
    },
    handleStore() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          await this.$api.saveProductDressInfo(this.formData)
          this.close()
        } else {
          // this.msgWarn('检查有必填项未填写')
        }
      })
    },
    handleCopy() {
      this.$set(this.formData, 'id', null)
      delete this.formData.id
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formData.validate(async (valid) => {
          if (valid) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    handleSelectPlatform(val) {
      this.formData.platform_code = val ? val.code : []
      this.formData.platform_name = val ? val.name : []
    }
  }
}
</script>

<style scoped>

</style>
