<template>
  <el-drawer :visible.sync='show' :title='`【${ProductName}】同步更新`' custom-class='product-history-drawer'>
    <div class='flex main'>
      <div class='drawer-card left'>
        <p>选择要同步的产品信息</p>
        <el-table ref='fields' :data='fields' style='width: 100%'>
          <el-table-column type='selection' width='55' />
          <el-table-column prop='_field' label='修改项目' align='center' width='110' show-overflow-tooltip />
          <el-table-column label='修改内容' align='center'>
            <template v-slot='{row:{_value}}'>
              <div v-html='_value' />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class='drawer-card center'>
        <p>选择要同步的排期</p>
        <div style='display: grid;grid-template-columns: repeat(4, calc((100% - 30px)/4));grid-column-gap: 10px;'>
          <artist-search :artist-id.sync='schedules_search.artist_id' @handleSelect='searchSchedules(1)' />
          <el-date-picker v-model='schedules_search.date' type='date' value-format='yyyy-MM-dd'
                          placeholder='请选择日期' style='width: 100%;' @change='searchSchedules(1)' />
          <platform-select :value.sync='schedules_search.platform_codes' range-all purpose='is_data_entry'
                           placeholder='平台' @change='searchSchedules(1)' />
          <el-select v-model='schedules_search.type' placeholder='排期类型' clearable @change='searchSchedules(1)'>
            <el-option label='红人选品' :value='1' />
            <el-option label='直播定品' :value='2' />
          </el-select>
        </div>
        <el-table ref='schedules' :data='schedules' style='width: 100%;margin: 10px 0;' row-key='id'
                  height='calc(100% - 115px)'>
          <el-table-column type='selection' width='55' reserve-selection />
          <el-table-column type='index' label='序号' width='55' />
          <el-table-column prop='nickname' label='红人昵称' align='center' show-overflow-tooltip />
          <el-table-column prop='platform_name' label='平台' align='center' show-overflow-tooltip />
          <el-table-column label='日期' align='center'>
            <template v-slot='{row:{start_at}}'>
              {{ start_at|parseTime }}
            </template>
          </el-table-column>
          <el-table-column label='类型' align='center'>
            <template v-slot='{row:{type}}'>
              <span v-if='type===1'>红人选品</span>
              <span v-else-if='type===2'>直播定品</span>
            </template>
          </el-table-column>
        </el-table>
        <div style='display: flex;justify-content: space-between;align-items: center;'>
          <el-pagination background :page-size='schedules_search.page_size'
                         :current-page.sync='schedules_search.current_page'
                         layout='prev, pager, next' :total='schedules_search.total' />
          <el-button v-if='$refs.schedules' size='mini' @click='$refs.schedules.clearSelection()'>
            清空（{{ $refs.schedules.selection.length || 0 }}）
          </el-button>
        </div>
      </div>
      <div class='tip drawer-card right'>
        <p>更新规则说明</p>
        <ul>
          <li>
            <b>1.</b>
            选品日期更新范围：<br /><b style='opacity: 0'>1.</b>产品修改日期往前推30天为截止日期，该日期往后的
            所有选品日期内选品表。比如产品修改日期2024年3月31日，范围应该为：
            2024年3月1日(包含)往后的选品日期都在范围内。
          </li>
          <li>
            <b>2.</b>
            直播日期更新范围：<br /><b style='opacity: 0'>2.</b>直播日期大于产品修改日期的排期。比如产品修改日
            期2024年3月31日，范围应该为：2024年3月31日(不包含)往后的直播日期
            都在范围内。
          </li>
          <li>
            <b>3.</b>流程说明：<br />
            <b style='opacity: 0'>3.</b><b>3.1</b>招商修改产品信息后，点击“保存”。跳转到修改产品信息更新页面。<br />
            <b style='opacity: 0'>3.</b><b>3.2</b>先在本次修改的产品信息中选择要同步的信息。<br />
            <b style='opacity: 0'>3.</b><b>3.3</b>选择要更新的排期
          </li>
        </ul>
      </div>
    </div>
    <div class='footer'>
      <el-button round size='medium' @click='show=false'>不同步更新</el-button>
      <el-button type='primary' round size='medium' @click='handleSure'>确认同步更新</el-button>
    </div>
  </el-drawer>
</template>
<script>
import ArtistSearch from '../../../../components/artist/ArtistSearch.vue'
import PlatformSelect from '../../components/PlatformSelect.vue'

export default {
  name: 'ProductHistorySync',
  components: { PlatformSelect, ArtistSearch },
  props: {
    ProductId: {
      type: [Number, String],
      required: true
    },
    ProductName: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      show: false,
      version: null,
      fields: [],
      schedules: [],
      schedules_search: {
        current_page: 1,
        page_size: 20,
        total: 0,
        product_id: this.ProductId,
        platform_codes: null,
        artist_id: null,
        date: null,
        type: null
      }
    }
  },
  watch: {
    'schedules_search.current_page'(_) {
      this.searchSchedules(_)
    }
  },
  methods: {
    getLastVersion: async function() {
      const { changes } = await this.$api.getProductVerDetail({ version: this.version, limit: true })
      if (Array.isArray(changes) && changes.length) {
        this.show = true
      }
      this.fields = changes || []
    },
    searchSchedules: function(current_page = 1) {
      this.schedules_search.current_page = current_page
      this.getSchedules()
    },
    getSchedules: async function() {
      const { data, total } = await this.$api.getProductSchedules(this.schedules_search)
      this.schedules = data || []
      this.schedules_search.total = total || 0
    },
    handleSure: function() {
      const fields = this.$refs.fields.selection
      if (!fields.length) {
        this.$message.warning('请先选择要同步的产品信息')
        return
      }
      const schedules = this.$refs.schedules.selection
      if (!schedules.length) {
        this.$message.warning('请选择要同步的排期')
        return
      }
      const lb_ids = schedules.map(_ => {
        return _.id
      })

      const params = {
        fields,
        lb_ids,
        version: this.version,
        product_id: this.ProductId
      }
      this.$confirm(`确认同步选中的商品信息给${schedules.length}个排期吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.syncScheduleProduct(params)
        this.$message.success('同步成功')
        this.show = false
      }).catch(() => {

      })
    },
    handleShowDrawer: function(version) {
      this.version = version
      this.getLastVersion()
      this.getSchedules()
    }
  }
}
</script>

<style lang='scss'>
.product-history-drawer {
  width: 100% !important;
  font-family: FjallaOne, DINPro Medium, sans-serif !important;

  p {
    margin: unset;
  }

  .el-table {
    p {
      margin: unset !important;
    }
  }

  .el-pagination {
    display: block;
    text-align: center;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .main {
    padding: 12px;
    background: #f7f8fa;
    height: calc(100% - 66px);
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px !important;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 89.8%, .5);
    //box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .drawer-card {
    border-radius: 12px;
    background: #fff;
    padding: 24px;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 89.8%, .5);

    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 24px !important;
    }
  }

  .drawer-card + .drawer-card {
    margin-left: 12px;
  }

  .left, .center {
    width: calc((100% - 200px - 24px) / 2);
  }

  .tip {
    width: 200px;
    font-size: 14px;
    line-height: 1.5;

    li + li {
      margin-top: 18px;
    }

    b {
      margin-right: 4px;
    }
  }

  .el-autocomplete {
    width: 100%;
  }
}
</style>
