<template>
  <div>
    <el-table :data='dataList' border stripe highlight-current-row>
      <el-table-column label='序号' type='index' width='55'></el-table-column>
      <el-table-column label='排期' align='left' header-align='center' min-width='300'>
        <template slot-scope='{row}'>
          <span>{{ $utils.parseTime(row.start_at, '{y}年{m}月{d}日') }}</span>
          <span>【{{ row.nickname || '' }}】</span>
          <span>{{ row.lb_platform_name }}</span>
          <span> 红人选品</span>
          <div v-if='row.lb_deleted_at' style='color: red;font-size: 0.9em'>该排期已于[{{row.lb_deleted_at}}]删除</div>
          <div v-if='row.p_deleted_at' style='color: red;font-size: 0.9em'>该选品已于[{{row.p_deleted_at}}]删除</div>
        </template>
      </el-table-column>
      <el-table-column label='操作人' prop='creator' align='center' min-width='100'></el-table-column>
      <el-table-column label='操作时间' prop='created_at' align='center' min-width='100' show-overflow-tooltip></el-table-column>
      <el-table-column label='选品状态' prop='state_alias' align='center' min-width='120' show-overflow-tooltip></el-table-column>
    </el-table>
    <div style='display: flex;flex-direction: row;justify-content: space-between;align-items: center'>
      <el-link @click='handleQuery' icon='el-icon-refresh'>刷新</el-link>
      <m-pagination @pagination='getList' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                    :total.sync='total'></m-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: 'KolSelectionRecord',
  data() {
    return {
      dataList: [],
      searchCondition: { product_id: null, type: 1 },
      pageData: { current_page: 1, page_size: 10 },
      total: 0
    }
  },
  methods: {
    show(query) {
      this.searchCondition.product_id = query.product_id || null
      this.handleQuery()
    },
    async getList() {
      this.dataList = []
      let search = { ...this.searchCondition }
      Object.assign(search, this.pageData)
      let { list, pages } = await this.$api.getProductLbRecord(search)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 10
      this.total = pages.total || 0
    },
    handleQuery() {
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
