<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose'
               :title='currentTitle'
               fullscreen :close-on-click-modal='false'>

      <el-row :gutter='20'>
        <el-col :span='13'>
          <el-card class='box-card' shadow='never'>
            <div slot='header' class='clearfix'>
              <span>{{ currentTitle }}</span>
            </div>
            <el-row>
              <el-col :span='24'>
                <el-form :model='searchData' inline>
                  <el-form-item label=''>
                    <el-date-picker
                      v-model='searchData.ym'
                      type='month'
                      value-format='yyyy-MM'
                      format='yyyy年MM月'
                      @change='handleQuery'
                      placeholder='选择月份' style='width: 140px'>
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label=''>
                    <artist-search-custom :type='``' @handleSelect='selectArtist' :show-first-group='false'
                                          style='width: 100%' />
                  </el-form-item>
                  <!--            <el-form-item label='发起人'>-->
                  <!--              <el-input v-model='searchData.leader_name' clearable placeholder='发起人：支持模糊搜索'></el-input>-->
                  <!--            </el-form-item>-->
                  <!--            <el-form-item label='状态'>-->
                  <!--              <el-select v-model='searchData.progress_status' style='width: 120px' clearable>-->
                  <!--                <el-option :label='option.label' :value='option.value' v-for='(option,key) in progressStatusOptions'-->
                  <!--                           :key='key'-->
                  <!--                           :style='`text-align: center;border-radius: 3px;margin:1px;border: #7d8086 1px solid;background-color:${option.color_value}`'></el-option>-->
                  <!--              </el-select>-->
                  <!--            </el-form-item>-->
                  <el-form-item>
                    <el-button type='primary' @click='handleQuery' icon='el-icon-search' :disabled='isInitLoading'
                               :loading='isInitLoading'>查询
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <div class='default-table'>
              <el-table :data='KolDataList' highlight-current-row border
                        :default-sort="{prop: 'deadline', order: 'ascending'}">
                <el-table-column type='index' label='序号' width='65' align='center'></el-table-column>
                <el-table-column label='日期' prop='date' align='center' min-width='100'></el-table-column>
                <el-table-column label='红人' prop='nickname' align='center' min-width='100'
                ></el-table-column>
                <el-table-column label='平台' prop='platform_code' align='center' min-width='100'>
                  <template slot-scope='{row}'>
                    <span>{{ row.platform_name || '-' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label='开播时间' prop='start_at' align='center' min-width='260'>
                  <template slot-scope='{row}'>
                  <span> {{
                      $utils.parseTime(row.start_at, '{y}-{m}-{d} {h}:{i}')
                    }} ~  {{ $utils.parseTime(row.end_at, '{y}-{m}-{d} {h}:{i}') }} </span>
                  </template>
                </el-table-column>
                <el-table-column label='操作' min-width='80' align='center' fixed='right'>
                  <template slot-scope='{row}'>
                    <el-button type='text' @click='handleCheck(row)' icon='el-icon-folder-add'>选择</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <m-pagination :total.sync='kolTotal' :limit.sync='KolPagingData.page_size'
                            v-if='kolTotal' :page.sync='KolPagingData.current_page'
                            @pagination='getKolList' />
            </div>
          </el-card>
        </el-col>
        <el-col :span='11'>
          <el-card class='box-card' shadow='never'>
            <div slot='header' class='clearfix'>
              <span>{{ currentTitle }}-服饰表</span>
              <el-button style='float: right; padding: 3px 0' type='text' @click='handleJoinBatch'>批量添加
              </el-button>
            </div>
            <el-form :model='searchCondition' inline>

              <el-form-item label=''>
                <el-input v-model='searchCondition.title' placeholder='产品名称' clearable @change='getList'></el-input>
              </el-form-item>
              <el-form-item label=''>
                <el-select clearable v-model='searchCondition.state' placeholder='红人意见' @change='getList'>
                  <el-option
                    v-for='item in stateOptions'
                    :key='item.value'
                    :label='item.label'
                    :value='item.value'>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' icon='el-icon-search' @click='getList'
                           size='mini' :disabled='loading' :loading='loading'>搜索
                </el-button>
              </el-form-item>
            </el-form>
            <div class='default-table'>
              <m-table :showIndex='false' :tableData.sync='dataList'
                       @selection-change='handleSelectionChange'
                       :columns='productColumns'
                       row-key='id'
                       :border='true'
                       max-height='600'
              >
                <el-table-column type='selection' width='40'
                                 slot='first-column'></el-table-column>
                <el-table-column type='index' width='55'
                                 slot='first-column' label='序号' align='center'></el-table-column>
                <el-table-column label='操作' min-width='80' align='center' fixed='right'>
                  <template slot-scope='{row}'>
                    <el-button type='text' @click='handleAddRow(row)' icon='el-icon-shopping-bag-1'
                    >添加
                    </el-button>
                  </template>
                </el-table-column>
              </m-table>
              <m-pagination :total.sync='total' :limit.sync='pageData.page_size'
                            v-if='total' :page.sync='pageData.current_page'
                            @pagination='getList' />
            </div>
          </el-card>
        </el-col>
      </el-row>

    </el-dialog>
  </div>
</template>

<script>
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'

export default {
  name: 'HistoricalPage',
  components: {
    ArtistSearchCustom
  },
  data() {
    return {
      loading: false,
      isInitLoading: false,
      searchData: {},
      KolDataList: [],
      dataList: [],
      productColumns: [
        { title: '产品图片', value: 'cover_url', type: 'image', width: 50 },
        { title: '红人意见', value: '_state', width: 80 },
        { title: '产品/品牌', value: 'title', field: 'title', align: 'left', width: 140 },
        { title: '尺码/颜色', value: 'spec_name', width: 120 },
        { title: '日常价', value: 'price', width: 80 },
        { title: '直播价', value: 'lb_price', width: 80 }
      ],
      searchCondition: {},
      stateOptions: [
        { label: '上播', value: 1, color_value: '#67C23A', class_name: 'status-new' },
        { label: '不上播', value: 2, color_value: '#909399', class_name: 'status-launch' },
        { label: '待定', value: 0, color_value: '#409EFF', class_name: 'status-original' }
      ],
      rowId: '',
      KolPagingData: {},
      kolTotal: 0,
      pageData: {},
      total: 0,
      selectionList: []
    }
  },
  props: {
    historicalType: {
      type: String,
      default() {
        return 'kol'
      }
    }
  },
  computed: {
    currentTitle() {
      return this.historicalType == 'kol' ? '历史选品会' : '历史直播'
    }
  },
  methods: {
    onOpen() {
      this.defaultTime()
      this.getKolList()
    },
    onClose() {
      this.historicalList = []
      this.dataList = []
      this.selectionList = []
      this.currentRow = {}
      // this.$emit('')
    },
    async getKolList() {
      this.isInitLoading = true
      let searchData = this.handlesearchData()
      let type = this.historicalType == 'kol' ? 1 : 2
      let order = { start_at: 'desc' }
      Object.assign(searchData, { type: type }, this.KolPagingData, { order: order })
      let { list, pages } = await this.$api.getLbScheduleList(searchData)
      this.KolDataList = list
      this.kolTotal = pages.total || 0
      this.KolPagingData.current_page = pages.current_page || 1
      this.KolPagingData.page_size = pages.page_size || 15
      this.isInitLoading = false
    },
    close() {
      this.$emit('update:visible', false)
    },
    selectArtist(selectInfo) {
      // console.log(selectInfo)
      this.searchData.artist_id = selectInfo.artist_id
      this.searchData.nickname = selectInfo.nickname
      this.searchData.group_id = selectInfo.group_id
      this.searchData.group_name = selectInfo.group_name
      this.handleQuery()
    },
    // 处理搜索条件
    handlesearchData() {
      let condition = {}
      if (this.searchData.ym) {
        condition.ym = this.searchData.ym
      }
      if (this.searchData.artist_id) {
        condition.artist_id = this.searchData.artist_id
      }
      // if (this.searchData.progress_status) {
      //   condition.progress_status = this.searchData.progress_status
      // }
      // if (this.searchData.leader_id) {
      //   condition.leader_id = this.searchData.leader_id
      // }
      // if (this.searchData.leader_name) {
      //   condition.leader_name = this.searchData.leader_name
      // }
      return condition
    },
    handleKolSelectionChange(val) {
      this.selectionList = val
    },
    handleQuery() {
      // this.pagingData.current_page = 1
      this.getKolList()
      // this.getLiveList()
      this.isInitLoading = false
    },
    defaultTime() {
      const end = new Date()
      this.$set(this.searchData, 'ym', end.getFullYear() + '-' + (end.getMonth() + 1))
    },
    handleSelectionChange(val) {
      this.selectionList = val
    },
    async getList() {
      this.loading = true
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getLbDressSelectionList(searchCondition)
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 10
      this.total = pages.total || 0
      this.loading = false
      // getLbDressSelectionList
    },
    handleCheck(val) {
      this.rowId = val.id
      this.getList()
    },
    async handleAddRow(val) {
      delete val.id
      let id = await this.$api.saveProductDressInfo(val)
      if (id) {
        this.$message.success('添加成功')
      }
      //   saveProductDressInfo
    },
    handleSearchCondition() {
      let cond = {}
      // if (this.searchCondition.brand_name) {
      //   cond['brand_name'] = this.searchCondition.brand_name
      // }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.state) {
        cond['state'] = this.searchCondition.state
      }
      // state
      cond['lb_id'] = this.rowId
      return cond
    },
    async handleJoinBatch() {
      console.log('add')
      console.log(this.selectionList)
      this.selectionList.forEach((item) => {
        delete item.id
      })
      let opt_name = this.historicalType == 'kol' ? '选品会' : '直播定品'
      let { total, ids, failed_rows } = await this.$api.batchCreateProductDressInfo(this.selectionList, opt_name)
      if (ids) {
        this.$notify.success(`导入：${total}条，成功（去重后）：${ids.length}条，失败：${failed_rows.length}条`)
        this.$message.success('提交成功')
      }

    }

  }
}
</script>
