<template>
  <div>
    <el-cascader
        v-bind='$attrs' v-on='$listeners'
        placeholder='选择类目'
        :props='cascaderProps'
        :options='categoryList'
        v-model='selectedValues'
        @change='cascaderChange'
        show-all-levels
        ref="cascades"
        clearable
        filterable>
    </el-cascader>
  </div>
</template>

<script>
export default {
  name: 'CategoryCascader',
  props: {
    identify: {
      type: String
    },
    selectedList: {
      type: Array
    },
    //是否多选，默认单选
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    selectedValues: {
      get() {
        return this.selectedList
      },
      set(val) {
        this.$emit('update:selected-list', val)
      }
    },
    cascaderProps() {
      return { value: 'id', label: 'display_name', multiple: this.multiple, checkStrictly: true }
    }
  },

  data() {
    return {
      // cascaderProps: {
      //   label: 'display_name',
      //   value: 'id',
      // },
      // 分类列表
      categoryList: []
      // 已选中的分类列表，用于cascader组件选中效果
      // selectedList: [],
    }
  },
  methods: {
    clearFun() {
      this.$refs['cascades'].panel.clearCheckedNodes()
      this.$emit('cascaderChange', [])
    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.$emit('cascaderChange', v)
    },
    async loadCategory() {
      // 获取分类列表
      let { list } = await this.$api.getCategoryListByIdentify(`${this.identify}-category`)
      this.categoryList = list || []

    },
    getNode(val){
      return this.categoryList.find(value => value.id===val)
    }
  },
  created() {
    this.loadCategory()
  }

}
</script>

<style scoped>

</style>
