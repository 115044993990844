<template>
  <div>
    <el-select v-model="leader"
               filterable
               placeholder="选品负责人"
               clearable>
      <el-option :label="item.label" :value="item.value" v-for="(item) in options" :key="item.value">
        <span style="float: left"> {{ item.label }}</span>
        <span style="float: right;color: #909399"> {{ item.total }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "SelectionLeaderSelect",
  props: {
    leaderId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      options: []
    }
  },
  computed: {
    leader: {
      get() {
        return this.leaderId
      },
      set(val) {
        this.$emit('update:leaderId', val)
        this.changeLeader(val)
      }
    }
  },
  methods: {
    async getOptions() {
      this.options = []
      let {list} = await this.$api.productSelectionLeaderOptions()
      this.options = list
    },
    changeLeader(val) {
      if (val && this.options.length) {
        let option = this.options.find(value => value.value === val)
        this.$emit('selected', option)
      } else {
        this.$emit('selected', {})
      }

    }
  },
  created() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>