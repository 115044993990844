<template>
  <el-dialog :title="`${isEdit?'修改':'新增'}寄样信息`"
             @open='onOpen' @close='onClose' v-bind='$attrs' v-on='$listeners' append-to-body center width='550px'>
    <el-form ref='formStore' :model='formStore' :rules='rules' label-poionsit='right' label-width='110px'>
      <el-form-item label='快递单号' prop='nu'>
        <el-input @blur="getComData" v-model='formStore.nu' placeholder='快递单号' clearable/>
      </el-form-item>
      <el-form-item label='手机号' prop='phone' v-if="showPhone">
        <el-input v-model="formStore.phone" class="input-with-select"
                  clearable maxlength="11"
                  size="mini" placeholder="请输入收或寄件人手机号"
                  show-word-limit
        >
          <el-button slot="append" icon="el-icon-phone-outline"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label='快递公司' prop='com_label'>
        <el-input v-model='formStore.com_label' placeholder='请输入快递公司' @change="formStore.com=null" clearable/>
      </el-form-item>
      <el-form-item label='商品件数' prop='qty'>
        <el-input oninput="value=value.replace(/[^\d^\.^\-]/g,'')" placeholder='请输入商品件数,必须是数值'
                  clearable v-model='formStore.qty'/>
      </el-form-item>
      <el-form-item label='是否需要退样' prop='need_back'>
        <el-radio-group v-model="formStore.need_back">
          <el-radio :label="'Y'">是</el-radio>
          <el-radio :label="'N'">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label='寄样备注' prop='remark'>
        <el-input clearable v-model='formStore.remark' placeholder='请输入寄样备注'/>
      </el-form-item>
    </el-form>
    <span slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='handelStore'>确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
const rules = {
  nu: [
    { required: true, message: '请输入物流单号', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ],
  phone: [{ required: true, message: '顺丰快递必须传收或寄手机号', trigger: 'blur' }],
  need_back: [{ required: true, message: '请选择是否需要退样', trigger: 'blur' }]
}
export default {
  name: 'EditSpecimen',
  props: {
    id: {
      type: [Number, String]
    },
    isEdit: {
      type: Boolean,
      default() {
        return false
      }
    },
    formStore: {
      type: Object,
      default() {
        return {
          nu: null,
          com: null,
          com_label: null,
          qty: 1,
          need_back: null,
          remark: null
        }
      }
    }
  },
  data() {
    return {
      rules: rules,
      showPhone: false,
      sfData: ['shunfengkuaiyun', 'shunfeng', 'shunfenghk', 'nsf', 'shunfenglengyun', 'shunfengnl']
    }
  },
  methods: {
    onOpen() {
      if(this.formStore?.id){
        this.showPhone = this.sfData.indexOf(this.formStore.com) > -1
      }else this.showPhone=false
    },
    onClose() {

      this.$emit('dialogStore', false)
    },
    async getComData() {
      if (this.formStore.nu) {
        let { comCode, name } = await this.$api.getLbProductSpecimenNuCom({ nu: this.formStore.nu })
        this.formStore.com_label = name
        this.formStore.com = comCode
        this.showPhone = (this.sfData.indexOf(comCode) > -1)
      }
    },
    async handelStore() {
      this.$refs.formStore.validate(async (valid) => {
        if (valid) {
          this.$emit('update:formStore', this.formStore)
          this.$emit('submitData', true)
          // let { nu, com, qty, need_back, remark, com_label } = this.formStore
          // let data = {
          //   nu: nu,
          //   com: com,
          //   com_label: com_label,
          //   remark: remark,
          //   items: [{
          //     product_id: this.id,
          //     qty: qty,
          //     need_back: need_back
          //   }]
          // }
          // let id = await this.$api.addLbProductSpecimenCommit(data)
          // if (id) {
          //   this.$message.success('新增成功！')
          //   this.dialogStore = false
          //   await this.getLogisticList()
          // } else {
          //   this.$message.error('未知错误,请联系管理员')
          // }
        }
      })
    }

  }
}
</script>

<style scoped>

</style>
