<template>
  <div class='product-specimen-save-container'>
    <el-button type='primary' icon='el-icon-plus' @click='handleShowDrawer'>
      新增寄样
    </el-button>
    <el-drawer title='新增寄样信息' :visible.sync='show' custom-class='product-specimen-save-drawer'
               :append-to-body='true'>
      <el-form ref='form' :model='form' :rules='rules' label-width='80px' size='medium'>
        <el-form-item label='商品信息'>
          <div class='products'>
            <div class='product' v-for='(product,i) in form.items' :key='i'>
              <div class='product-pic'>
                <img :src='product.cover_url' alt=''>
              </div>
              <div class='product-info'>
                <div class='product-title'>{{ product.title }}</div>
                <div class='product-spec'>
                  <span style='color: #666666'>规格：</span>{{ product.spec_name || '/' }}
                </div>
              </div>
              <div style='display: flex;align-items: center;justify-content: center;'>
                <div>
                  <el-input-number v-model='product.qty' size='mini' :min='1'
                                   style='width: 160px;margin-bottom: 4px;' />
                  <br />
                  <el-radio-group v-model='product.need_back' size='mini'>
                    <el-radio-button label='Y'>需要退样</el-radio-button>
                    <el-radio-button label='N'>无需退样</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label='快递单号' prop='nu'>
          <el-input v-model='form.nu' placeholder='请输入快递单号' />
        </el-form-item>
        <el-form-item label='快递公司' prop='com_label'>
          <el-input v-model='form.com_label' placeholder='请输入快递公司' />
        </el-form-item>
        <el-form-item v-if="form.com_label&&form.com_label.indexOf('顺丰')>-1" label='手机号码' prop='phone'>
          <el-input v-model='form.phone' placeholder='请输入寄/收件人手机号码' />
        </el-form-item>
        <el-form-item label='寄样备注'>
          <el-input type='textarea' placeholder='可输入备注信息' v-model='form.remark' maxlength='50' show-word-limit />
        </el-form-item>
      </el-form>
      <div style='height: 80px;' />
      <div class='footer'>
        <el-button type='primary' size='medium' :disabled='loading' :loading='loading' @click='handleSubmit'>
          提交
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>

const rules = {
  nu: [
    { required: true, message: '请填写快递单号', trigger: 'blur' }
  ],
  com_label: [
    { required: true, message: '请填写快递公司', trigger: 'change' }
  ],
  phone: [
    { required: true, message: '请输入寄/收件人手机号码', trigger: 'blur' },
    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误' }
  ]
}
const ModelForm = {
  nu: null,
  com: null,
  com_label: '',
  phone: null,
  remark: null,
  items: []
}
export default {
  name: 'ProductSpecimenSave',
  props: {
    getProducts: { type: Function }
  },
  data() {
    return {
      rules,
      loading: false,
      show: false,
      form: { ...{}, ...ModelForm }
    }
  },
  watch: {
    'form.nu'() {
      this.getCom()
    }
  },
  methods: {
    handleShowDrawer: function() {
      // 重置表单
      this.form = { ...{}, ...ModelForm }
      const products = this.getProducts()
      if (!products.length) {
        this.$confirm('请先选择要寄样的商品', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        })
        return false
      }
      this.form.items = products.map((_) => {
        return {
          product_id: _.id,
          qty: 1,
          need_back: '',
          cover_url: _.cover_url,
          title: _.title,
          spec_name: _.spec_name
        }
      })
      this.show = true
    },
    getCom: async function() {
      if (!this.form.nu) {
        return
      }
      const { comCode, name } = await this.$api.getLbProductSpecimenNuCom({ nu: this.form.nu })
      this.form.com_label = name
      this.form.com = comCode
    },
    handleSubmit: async function() {
      if (this.loading) {
        return false
      }
      const attributes = { ...{}, ...this.form }
      let error = null
      attributes.items.map(_ => {
        if (!_.need_back) {
          error = `请为【${_.title}】选择是否需要退样？`
        }
      })
      if (error) {
        this.$notify({ type: 'warning', title: '信息不完善', message: error })
        return false
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 2000)
          await this.$api.addLbProductSpecimenCommit(attributes)
          this.$message.success('操作成功')
          this.$emit('success')
          this.show = false
          setTimeout(() => {
            this.loading = false
          }, 1000)
        }
      })
    }
  }
}
</script>

<style lang='scss'>
.product-specimen-save-drawer {
  width: 650px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 20px;
  }

  .products {
    border-bottom: 1px solid #e6e6ed;
    padding-bottom: 20px;
    margin-bottom: 12px;
  }

  .product {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    background-color: #F5F5F5;
    border-radius: 18px;
    padding: 12px;

    .product-pic {
      width: 80px;
      height: 80px;
      line-height: 80px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      border-radius: 3px;
      border: 1px solid #eee;
      overflow: hidden;
      background: #fff;
      text-align: center;

      img {
        max-height: 80px;
        max-width: 80px;
        vertical-align: middle;
      }
    }

    .product-info {
      position: relative;

      .product-title {
        width: 250px;
        line-height: 1.2;
        color: #1a1a1a;
      }

      .product-spec {
        position: absolute;
        bottom: 0px;
      }
    }
  }

  .el-checkbox__label {
    padding-left: 3px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }
}
</style>
