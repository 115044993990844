<template>
  <div>
    <el-image
        @click="handlePreviewClick(imageUrl)"
        :style="radius?{borderRadius:'6px'}:{}"
        :src="imageUrl" alt="产品缩略图" style="width: 100%;height: 100%;" :fit="'contain'"
        :preview-src-list="pc_url">
      <div slot="error" class="image-slot">
        <svg-icon icon-class="errorImg" style="font-size: 120px;display: block"></svg-icon>
      </div>
    </el-image>
  </div>
</template>

<script>
export default {
  name: "ProductImage",
  props: {
    imageUrl: {
      type: String,
      default() {
        return ''
      }
    },
    radius: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      pc_url: []
    }
  },
  methods: {
    handlePreviewClick(val) {
      this.pc_url = [val]
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .image-slot {
  /*width: 80px;*/
  /*height: 80px;*/
  margin: 22% auto;
  display: block;
  text-align: center;

}
</style>
